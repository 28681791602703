import Image from "next/image";
import React from "react";
import { useMediaQuery, useToggle } from "../../../../hooks";
import { RequestModal } from "../RequestSection/RequestModal";
import clsx from "clsx";
import Link from "next/link";

const LandingBanner = ({ image, imageMobile, link }) => {
  const [showModal, toggle, off] = useToggle();
  const isDesktop = useMediaQuery("(min-width:500px)");

  return (
    ((isDesktop && !!image) || (!isDesktop && !!imageMobile)) && (
      <section className="landing-banner landing-main container">
        {showModal && <RequestModal onClose={off} />}
        {!!link ? (
          <Link
            href={link || "/"}
            passHref
            className={clsx("landing-banner__wrapper", {
              ["landing-banner__wrapper--desktop"]: isDesktop,
            })}
          >
            <Image src={isDesktop ? image : imageMobile} alt="banner" priority={true} fill />
          </Link>
        ) : (
          <button
            className={clsx("landing-banner__wrapper", {
              ["landing-banner__wrapper--desktop"]: isDesktop,
            })}
            onClick={toggle}
          >
            <Image src={isDesktop ? image : imageMobile} alt="banner" priority={true} fill />
          </button>
        )}
      </section>
    )
  );
};

export { LandingBanner };
