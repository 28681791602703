import React from "react";

import AdvantageSection from "./AdvantageSection/AdvantageSection";
import FeaturesSection from "./FeaturesSection/FeaturesSection";
import PriceSection from "./PriceSection/PriceSection";

import ReviewsSection from "./ReviewsSection/ReviewsSection";
import TopClubs from "../../common/TopClubs";
// import PartnersSection from "./PartnersSection/PartnersSection";
import SeoTextBlock from "../../common/SeoTextBlock/SeoTextBlock";

import { useLandingSettingsQuery } from "../../../utils/apollo/useLandingSettingsQuery";
import { LandingHeader } from "./LandingHeader/LandingHeader";
import { IS_FULL_CLIENT_SITE } from "../../../constants/APIKeys";
import { LandingBanner } from "./LandingBanner";

const Landing = () => {
  const { data: { social = {}, banner = {} } = {} } = useLandingSettingsQuery();  
  const { advantages = [], priceBlocks = [], features = [] } = social;

  return (
    <>
      {!!banner && <LandingBanner image={banner.image} imageMobile={banner.imageMobile} link={banner.link} />}
      <LandingHeader social={social} />

      {!!advantages.length && <AdvantageSection advantages={advantages} />}
      {!!priceBlocks.length && <PriceSection prices={priceBlocks} />}
      {!!features.length && <FeaturesSection features={features} />}

      <ReviewsSection />

      {IS_FULL_CLIENT_SITE && <TopClubs />}
      <SeoTextBlock />
      {/* <PartnersSection /> */}

      <style jsx global>{`
        @import "./Landing.scss?";
        @import "./LandingBanner/LandingBanner.scss?3";
      `}</style>
    </>
  );
};

export default Landing;
